.About {
  display: flex;
  min-width: 200px;
  margin: 25px;
}

.Titles {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.Titles h1 {
  font-size: 24px;
  margin: 0 0 10px 0;
  color: #123c69;
  font-family: "Exo", sans-serif;
}

.Titles h2 {
  font-size: 20px;
  margin: 0 0 5px 0;
  color: #bab2b5;
  font-family: "Exo", sans-serif;
}

.Titles p {
  font-size: 16px;
  margin: 15px 0 0 0;
  padding: 0px;
  font-family: "Ubuntu", sans-serif;
}
