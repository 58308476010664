.Blurb {
    font-size: 12px;
    color: grey;
}

.ContactIcons {
    text-align: center;
    box-shadow: 3px 3px 5px 1px #EDC7B7;
    background-color: #EEE2DC;
    width: 40%;
    min-width: 250px;
    margin: 25px auto;
}

.ContactIcons svg {
    color: #AC3B61;
    font-size: 36px;
    margin: 15px;
    transition: all 0.4s ease-out;
}

.ContactIcons svg:hover {
    color: #123C69;
    transform: scale(1.1);
}

.ContactForm input, textarea {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0px;
    font-family: 'Exo', sans-serif;
    box-sizing: border-box;
    border: 1px solid #ccc;
}

.ContactForm input:focus {
    outline: none;
}

.ContactForm button {
    margin: 25px auto;
    display: block;
    padding: 10px 25px;
    border-radius: 5px;
    background-color: #123C69;
    color: white;
    font-family: 'Exo', sans-serif;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.4s ease-out
}

.ContactForm button:hover {
    background-color: #1b5b9e;
    cursor: pointer;
}

.Contact {
    width: 400px;
}