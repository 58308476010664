.WorkExperience {
  text-align: left;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  font-family: "Ubuntu", sans-serif;
  max-width: 500px;
}

.Titles {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.Titles h1 {
  color: #123c69;
  padding-bottom: 5px;
  margin: 0px;
  font-family: "Exo", sans-serif;
}

.Titles h3 {
  color: #bab2b5;
  padding-bottom: 5px;
  margin: 0px;
  font-family: "Exo", sans-serif;
}

.Titles h5 {
  color: #c0c0c0;
  padding: 0px;
  margin: 0px;
  font-family: "Exo", sans-serif;
}

.WorkExperience img {
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 5px;
}

.Tech {
  background-color: #eee2dc;
  color: #ac3b61;
  margin: 0 auto;
  padding: 15px;
  border-radius: 5px;
  font-family: "Rokkitt", sans-serif;
}
