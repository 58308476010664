.Picture {
  border-radius: 50%;
  background-color: #ebf4fa;
  width: 100px;
  height: 100px;
  margin: 25px auto;
  padding: 0px;
  background-image: url("./me.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(0 0 5px #206ba4);
}

.PictureHover {
  opacity: 0;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.PictureHover:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.25);
}

.PictureHover span {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 95%;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
}

.PictureHover span:hover {
  cursor: pointer;
}
