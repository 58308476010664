@keyframes popIn {
    0% {
        transform: rotateY(180deg);
        color: #EDC7B7;
        background-color: #EDC7B7;
    }
    100% {
        color: #EDC7B7;
        background-color: #EDC7B7;
    }
}

.Project {
    width: 200px;
    background-color: #EDC7B7;
    padding: 0px 15px 15px 15px;
    margin: 25px;
    display: inline-block;
    overflow: hidden;
    height: 200px;
    transition: all .4s ease-out;
    border-radius: 10px;
    animation-name: popIn;
    animation-duration: 0.75s;
    animation-timing-function: ease-in;
}

.Project::-webkit-scrollbar {
    width: 0px;
}

div.Project:hover {
    overflow-y: scroll;
    background-color: #BAB2B5;
    cursor: pointer;
}

.Project p {
    font-size: 14px;
    animation-name: popIn;
    animation-duration: 0.75s;
    animation-timing-function: ease-in;
}

.Project h1 {
    font-family: 'Exo', sans-serif;
    font-size: 24px;
    color: #AC3B61;
    animation-name: popIn;
    animation-duration: 0.75s;
    animation-timing-function: ease-in;
}

.TechWords {
    background-color: #eee2dc;
    font-size: 12px;
    color: #123C69;
    text-align: left;
    padding: 8px;
    border-radius: 5px;
    font-family: 'Rokkitt', sans-serif;
}

.Project button {
    border: 1px solid #AC3B61;
    border-radius: 5px;
    background-color: #dd4b7c;
    transition: background-color 0.4s ease-out;
    color: white;
    font-family: 'Rokkitt', sans-serif;
    font-size: 18px;
    padding: 5px;
}

.Project button:hover {
    background-color: #AC3B61;
    cursor: pointer;
}