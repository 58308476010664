@media(min-width: 501px) {
    .AboutImg {
        background-image: url('../../../assets/about.jpg');
        background-position-x: 75%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        filter: drop-shadow(0 0 5px #206BA4);
        height: 300px;
        width: 100px;
        margin-right: 25px;
    }
}

@media(max-width: 500px) {
    .AboutImg {
        display: none;
    }
}